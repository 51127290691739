import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, MenuItem, MenuList, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

import { Company, Shift, Site, TimeAndAttendanceLocation } from '@vyce/core/src/types';
import {
  AppDataGridWithSavedPage,
  AppLink,
  AppSwitch,
  ConfirmDialog,
  GridActions,
} from '@vyce/core/src/components';
import { deleteSiteRequest, getSitesRequest } from '@vyce/core/src/api/time';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { siteToLocation } from '@vyce/core/src/modules/timeModule/utils';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks/useDebounceValue';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { isNil } from '@vyce/core/src/utils';

import { useCheckClockedInWorkers } from '../LocationPage/hooks/useCheckClockedInWorkers';

interface Props {
  selectedCompany?: Company;
  fetchCompanyData?: Function;
}

const defaultSortModel: GridSortModel = [{ field: 'name', sort: 'asc' }];
export const LocationsAndShifts: React.FC<Props> = ({ selectedCompany, fetchCompanyData }) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [locations, setLocations] = useState<TimeAndAttendanceLocation[]>([]);
  const history = useHistory();
  const [openCloseDialog, setOpenCloseDialog] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<TimeAndAttendanceLocation | null>(null);
  const { isMobile } = useContext(DeviceContext);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const {
    sortModel,
    offset,
    substring,
    total,
    setTotal,
    setOffset,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({ defaultSortModel });
  const { closeForbidDialog, isForbidDIalogOpen, handleCloseClick } = useCheckClockedInWorkers({
    companyId: selectedCompany?.uuid,
    successCallback: () => setOpenCloseDialog(true),
  });

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Location Name',
        flex: 1,
        disableColumnMenu: true,
        minWidth: 150,
        renderCell: params => (
          <Box display="flex" width="100%">
            {!isClosed && !loading && (
              <AppLink to={`/time/locations-and-shifts/${params.row.name}_${params.row.uuid}?mode=preview`}>
                {params.row?.name}
              </AppLink>
            )}
            {isClosed && !loading && <Typography variant="subtitle2">{params.row?.name}</Typography>}
          </Box>
        ),
      },
      {
        field: 'teamMembersCount',
        headerName: 'Team members',
        flex: 0.8,
        disableColumnMenu: true,
        minWidth: 100,
      },
      {
        field: 'shifts',
        headerName: 'Shifts',
        flex: 0.9,
        minWidth: 150,
        sortable: false,
        disableColumnMenu: true,
        renderCell: params => (
          <Box display="flex" flexDirection="column" height={64} justifyContent="center">
            {params.row.shifts?.map((shift: Shift, index: number) => (
              <Typography style={{ fontWeight: 500 }} variant="caption" key={shift.name + index}>
                {shift.name}
              </Typography>
            ))}
          </Box>
        ),
      },
      {
        field: '',
        headerName: '',
        width: 80,
        hideSortIcons: true,
        sortable: false,
        disableColumnMenu: true,
        renderCell: params => (
          <Box display="flex" width="100%">
            {!isClosed && (
              <GridActions close={closeGridAction}>
                <MenuList>
                  <MenuItem onClick={() => goToLocation(params.row as TimeAndAttendanceLocation, 'preview')}>
                    Preview
                  </MenuItem>
                  <MenuItem onClick={() => goToLocation(params.row as TimeAndAttendanceLocation, 'edit')}>
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleCloseLocationClick(params.row as TimeAndAttendanceLocation)}>
                    Close location
                  </MenuItem>
                  {/* <MenuItem onClick={() => handleDeleteLocationClick(params.row as TimeAndAttendanceLocation)}>
                Delete location
              </MenuItem> */}
                </MenuList>
              </GridActions>
            )}
          </Box>
        ),
      },
    ],
    [closeGridAction, isClosed, loading]
  );

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const handleCloseLocationClick = async (location: TimeAndAttendanceLocation) => {
    setSelectedLocation(location);
    if (!location.uuid) {
      return;
    }
    resetCloseStatus();
    handleCloseClick(location?.uuid);
  };

  const goToLocation = (location: TimeAndAttendanceLocation, mode: string) => {
    history.push(`/time/locations-and-shifts/${location.name}_${location.uuid}?mode=${mode}`);
  };

  const getLocations = useCallback(async () => {
    if (!selectedCompany?.uuid || isNil(dOffset)) {
      return;
    }
    setLoading(true);
    try {
      const res = await getSitesRequest(selectedCompany.uuid, {
        order_by: formatSortModel<TimeAndAttendanceLocation>(sortModel),
        offset: dOffset as number,
        substring,
        limit: GRID_PAGE_SIZE,
        show_deleted: isClosed,
        only_inactive: !isClosed,
        only_active: isClosed,
      });
      const locations: TimeAndAttendanceLocation[] = res.data.items.map((item: Site) =>
        siteToLocation(item)
      );
      setTotal(res.data?.count || 0);
      setLocations(locations);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [dOffset, sortModel, selectedCompany, substring, isClosed]);

  const closeLocation = async () => {
    if (!selectedCompany?.uuid || !selectedLocation?.uuid) {
      return;
    }

    try {
      await deleteSiteRequest(selectedCompany.uuid, selectedLocation.uuid);
      setSelectedLocation(null);
      setOpenCloseDialog(false);
      showNotification({ message: 'The location has been closed.', options: { variant: 'success' } });
      await getLocations();
      if (fetchCompanyData) {
        fetchCompanyData(selectedCompany.uuid);
      }
    } catch (e) {
      handleServerError(e);
    }
  };

  const goToCreateLocationPage = () => {
    history.push('/time/add-new-location');
  };

  const handleActiveStatusChange = (status: boolean) => {
    setIsClosed(status);
    setOffset(0);
  };

  const getColumnsWithoutActions = () => {
    return columns.map(col => {
      if (!col.headerName) {
        return { ...col, renderCell: undefined };
      }
      return col;
    });
  };

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  return (
    <>
      <Box display="flex" gridGap={16} marginBottom={2}>
        <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />

        <Box flex={1} />

        <AppSwitch
          width={170}
          labelOffset={20}
          checked={isClosed}
          variant="paper"
          size="small"
          onChange={e => handleActiveStatusChange(e.target.checked)}
          leftLabel="Active"
          rightLabel="Closed"
        />

        {/*<FilterSystem filtersSections={[]} onFiltersChange={noop} />*/}

        <Button
          onClick={goToCreateLocationPage}
          variant="contained"
          size="small"
          startIcon={<AiOutlinePlus />}
          color="primary">
          Add new Location
        </Button>
      </Box>

      <AppDataGridWithSavedPage
        rows={locations || []}
        getRowId={row => row.uuid}
        columns={columns}
        noPaper
        rowHeight={80}
        height="calc(100vh - 240px)"
        loading={loading}
        rowCount={total}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        disableSelectionOnClick
        setOffset={setOffset}
      />

      <ConfirmDialog
        handleClose={() => {
          setOpenCloseDialog(false);
          setSelectedLocation(null);
        }}
        open={openCloseDialog}
        confirmText="Close Location"
        cancelText="Cancel"
        title="Do you want to close this Location?"
        subtitle="This means that no-one will be able to clock in or clock out of this location going forward."
        handleConfirm={() => closeLocation()}
      />

      {/* TODO: implement when Delete Location Completely api will be done */}
      {/* <ConfirmDialog
        handleClose={() => {
          closeDeleteDialog();
          setSelectedLocation(null);
        }}
        open={isDeleteDialogOpen}
        confirmText="Delete Location"
        cancelText="Cancel"
        title="Do you want to delete this Location?"
        subtitle="This action cannot be undone. All the workers assign to this location will be notified."
        handleConfirm={() => deleteLocation()}
      /> */}

      <ConfirmDialog
        handleClose={() => {
          closeForbidDialog();
          setSelectedLocation(null);
        }}
        open={isForbidDIalogOpen}
        cancelText="Ok, close"
        title="You can't delete this location"
        subtitle="Workers are still checked in, wait until the end of the day."
      />
    </>
  );
};
