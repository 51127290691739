import { Box, useTheme } from '@material-ui/core';
import { IoMdArrowDown } from 'react-icons/io';
import { IoMdArrowUp } from 'react-icons/io';
import { Theme } from '@material-ui/core/styles';
import type { GridSortModel } from '@mui/x-data-grid';
import clsx from 'clsx';

import { DetailsIcon } from '../../DetailsIcon';
import { EnrichedColumnProps } from '../types';
import { useStyles } from '../styles';
import { AppIconButton } from '../../AppIconButton';

export const ColumnItem = <T,>({
  column,
  sortModel,
  onSortModelChange,
}: {
  column: EnrichedColumnProps<T>;
  sortModel?: GridSortModel[0];
  onSortModelChange?: (newModel: GridSortModel) => void;
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isSortedByColumn = column.field === sortModel?.field;
  const Icon = isSortedByColumn && sortModel?.sort === 'desc' ? IoMdArrowDown : IoMdArrowUp;

  const handleChangeSortMode = () => {
    if (!onSortModelChange) return;
    if (!sortModel) {
      onSortModelChange([{ field: column.field as string, sort: 'asc' }]);
    }
    if (sortModel?.sort === 'desc') {
      onSortModelChange([]);
    }
    if (sortModel?.sort === 'asc') {
      onSortModelChange([{ field: column.field as string, sort: 'desc' }]);
    }
  };
  return (
    <Box
      minWidth={column.width || 'auto'}
      display="flex"
      gridGap={8}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      alignItems="center"
      onClick={handleChangeSortMode}
      className={classes.columnItem}>
      {column.headerName}
      {column.sortable && (
        <AppIconButton
          variant="grey"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          className={clsx({ [classes.hiddenSortButton]: !isSortedByColumn })}>
          <Icon color={theme.palette.text.secondary} size="18px" />
        </AppIconButton>
      )}
      {column.hint && <DetailsIcon size="16px" popoverClass={classes.hint} content={<>{column.hint}</>} />}
    </Box>
  );
};
