import React, { useCallback, useEffect, useRef } from 'react';

import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { AutoSizer, InfiniteLoader, ColumnSizer, ScrollSync, Grid, IndexRange } from 'react-virtualized';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import { ColumnItem } from './components/ColumnItem';
import { useStyles } from './styles';
import { Props } from './types';
import { RowItem } from './components/RowItem';
import { NoRowsOverlayComponent } from '../NoRowsOverlayComponent';

const columnMaxWidth = 1000;
const columnMinWidth = 200;
export const defaultIsLoadingList = { 0: true };

// useMemo for columns is required
// use hook useInfiniteScroll
// reference is packages/core/src/views/widgets/LocationUsersWidget
export const InfiniteTable = <T,>({
  data,
  columns,
  rowCount,
  rowHeight = 42,
  isLoadingList,
  sortModel,
  height,
  onLoadMore,
  onSortModelChange,
  resetLoadingList,
}: Props<T>) => {
  const classes = useStyles();
  const gridRef = useRef<Grid | null>(null);
  const infiniteLoaderRef = useRef<InfiniteLoader | null>(null);

  function isRowLoaded({ index }: { index: number }) {
    return !!data[index];
  }

  const loadMoreRows = ({ startIndex, stopIndex }: IndexRange) => {
    if (isLoadingList?.[startIndex]) {
      return;
    }

    onLoadMore(startIndex);
  };

  useEffect(() => {
    resetLoadingList();
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache(true);
    }

    if (gridRef.current) {
      gridRef.current.recomputeGridSize();
      gridRef.current.scrollToPosition({ scrollTop: 0, scrollLeft: 0 });
    }
  }, [columns, rowCount]);

  const renderCell = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    key: string | number;
    rowIndex: number;
    style: any;
  }) => {
    return (
      <Box key={key} className={classes.cell} style={style} flexGrow={columns[columnIndex].flex ?? 0}>
        {data[rowIndex] && !isLoadingList[rowIndex] ? (
          <RowItem row={data[rowIndex]} column={columns[columnIndex]} />
        ) : (
          <Skeleton variant="text" width={columns[columnIndex]?.width || 'auto'} />
        )}
      </Box>
    );
  };

  const renderHeaderCell = ({
    columnIndex,
    key,
    style,
  }: {
    columnIndex: number;
    key: string | number;
    style: any;
  }) => {
    return (
      <Box className={classes.cell} key={key} style={style}>
        <ColumnItem
          column={columns[columnIndex]}
          sortModel={sortModel?.[0]}
          onSortModelChange={onSortModelChange}
        />
      </Box>
    );
  };

  const getNoRowComponent = useCallback(() => <NoRowsOverlayComponent />, []);

  return (
    <Box height={height || '100%'}>
      <ScrollSync>
        {({ onScroll, scrollLeft }) => {
          return (
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              ref={infiniteLoaderRef}
              loadMoreRows={loadMoreRows as (params: any) => Promise<any>}
              rowCount={rowCount}>
              {({ onRowsRendered, registerChild: infiniteLoaderRegisterChild }) => {
                return (
                  <Box height={`calc(100% - ${rowHeight}px)`}>
                    <AutoSizer>
                      {({ width, height }) => (
                        <ColumnSizer
                          columnMaxWidth={columnMaxWidth}
                          columnMinWidth={columnMinWidth}
                          rowHeight={rowHeight}
                          columnCount={columns.length}
                          key="GridColumnSizer"
                          width={width}>
                          {({ adjustedWidth, columnWidth, registerChild: columnSizerRegisterChild }) => (
                            <Box width={adjustedWidth}>
                              <Box width="100%">
                                <Grid
                                  className={classes.headerGrid}
                                  columnWidth={columnWidth}
                                  columnCount={columns.length}
                                  height={rowHeight}
                                  cellRenderer={renderHeaderCell}
                                  rowHeight={rowHeight}
                                  rowCount={1}
                                  width={adjustedWidth - scrollbarSize()}
                                  ref={columnSizerRegisterChild}
                                  scrollLeft={scrollLeft}
                                />
                              </Box>
                              <Box height={height} width={adjustedWidth}>
                                <Grid
                                  className={classes.bodyGrid}
                                  columnWidth={columnWidth}
                                  columnCount={columns.length}
                                  height={height - scrollbarSize()}
                                  cellRenderer={renderCell}
                                  rowHeight={rowHeight}
                                  rowCount={rowCount}
                                  width={adjustedWidth}
                                  overscanRowCount={5}
                                  ref={grid => {
                                    infiniteLoaderRegisterChild(grid);
                                    columnSizerRegisterChild(grid);
                                    gridRef.current = grid;
                                  }}
                                  onScroll={onScroll}
                                  onSectionRendered={({ rowStartIndex, rowStopIndex }) =>
                                    onRowsRendered({
                                      startIndex: rowStartIndex,
                                      stopIndex: rowStopIndex,
                                    })
                                  }
                                  noContentRenderer={getNoRowComponent}
                                />
                              </Box>
                            </Box>
                          )}
                        </ColumnSizer>
                      )}
                    </AutoSizer>
                  </Box>
                );
              }}
            </InfiniteLoader>
          );
        }}
      </ScrollSync>
    </Box>
  );
};
