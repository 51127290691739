import React, { useContext, useEffect, useState } from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';
import difference from 'lodash/difference';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { AppA, AppDataGrid, AppChip } from '@vyce/core/src/components';
import { DeviceContext, TeamModuleContext } from '@vyce/core/src/contexts';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { AppSearchInput } from '@vyce/core/src/components/inputs';

const chipStyle = { height: 'auto' };

export const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none',
    },
  },
}));

const columns: GridColDef[] = [
  {
    field: 'first_name',
    headerName: 'Name',
    flex: 2,
    minWidth: 120,
    disableColumnMenu: true,
    valueGetter: params => `${params.row.first_name} ${params.row.last_name}`,
  },
  {
    field: 'email',
    headerName: 'Email #',
    flex: 1.5,
    minWidth: 270,
    disableColumnMenu: true,
    renderCell: params => (
      <AppA cy-test-id="user-email" content={params.row.email} href={`mailto: ${params.row.email}`} />
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone Number',
    disableColumnMenu: true,
    minWidth: 180,
    renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
  },
];

const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const EmployeesForTransferList = () => {
  const [innerSelectionModel, setInnerSelectionModel] = useState<string[]>([]);
  const { sortModel, offset, substring, handleSortModelChange, handlePageChange, handleSearchChange } =
    useTable({ defaultSortModel });
  const classes = useStyles();
  const {
    selectionModel,
    setSelectionModel,
    selectedCompanyId,
    teamId,
    employees,
    transferTotal: total,
    transferLoading: loading,
    getNonMembers,
  } = useContext(TeamModuleContext);
  const { isMobile } = useContext(DeviceContext);

  const handleSelectionModelChange = (newSelectionModel: string[]) => {
    setInnerSelectionModel(newSelectionModel);
    setSelectionModel(newSelectionModel);
  };

  const handleClearSelectedWorkers = () => handleSelectionModelChange([]);

  useEffect(() => {
    getNonMembers({ offset, substring, sortModel });
  }, [substring, offset, sortModel, selectedCompanyId]);

  if (!(selectedCompanyId && teamId)) return null;

  return (
    <Box className={classes.tableWrapper}>
      <Box
        mb={2}
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignContent="center"
        gridGap={16}>
        <Box flex={1}>
          <AppSearchInput
            cypressId="search-existing-users"
            expanded
            isBorder
            onChange={handleSearchChange}
          />
        </Box>
        {selectionModel.length !== 0 && (
          <AppChip
            style={chipStyle}
            label={`${selectionModel.length} team member${selectionModel.length > 1 ? 's' : ''} selected`}
            onDelete={handleClearSelectedWorkers}
          />
        )}
      </Box>

      <AppDataGrid
        noPaper={true}
        rows={employees}
        getRowId={row => row.user_id}
        columns={columns}
        height="calc(100vh - 380px)"
        rowCount={total}
        loading={loading}
        paginationMode="server"
        pageSize={GRID_PAGE_SIZE}
        onPageChange={handlePageChange}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        checkboxSelection
        selectionModel={innerSelectionModel}
        onSelectionModelChange={newSelectionModel => {
          const newSelection = difference(newSelectionModel, selectionModel) as string[];
          if (newSelection.length) {
            newSelection
              ? handleSelectionModelChange([...selectionModel, ...newSelection])
              : handleSelectionModelChange(newSelectionModel as string[]);
          }
        }}
        disableSelectionOnClick
      />
    </Box>
  );
};
