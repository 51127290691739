import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { Avatar, Box, Typography, useTheme } from '@material-ui/core';
import { useHistory, useLocation, useParams, Route, Switch, useRouteMatch } from 'react-router-dom';

import { BackTo, AppTabs, PageLoading } from '@vyce/core/src/components';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { TabItem, TimeAndAttendanceLocation, TimeWorker, TimeWorkerShift, User } from '@vyce/core/src/types';
import { getTimeWorkerRequest } from '@vyce/core/src/api/time';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { UserPreview } from '@vyce/core/src/views/preview/user/UserPreview';

import { ActivityLogs } from '../ActivityLogs';
import { LocationsAndShiftsSettings } from './LocationsAndShiftsSettings';
import { getUrlWithoutLastParam } from './utils';
import { UserPreviewProps } from '../../preview/types';

interface Props extends Partial<UserPreviewProps> {
  locations: TimeAndAttendanceLocation[];
  companyId?: string;
  loading?: boolean;
  isLegend?: boolean;
}

export const WorkerDetails: React.FC<Props> = ({
  locations,
  companyId,
  rtw,
  passport,
  userPreviewData,
  loading,
  isLegend = false,
  showHealthAndSafetyPDFRequest,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { state } = useLocation<{ from?: string; nameOfPrevRoute?: string }>();
  const [worker, setWorker] = useState<TimeWorker>();
  const [nameOfPrevRoute] = useState(state?.nameOfPrevRoute ?? `User`);
  const { id } = useParams<{ id: string }>();
  const [shits, setShits] = useState<TimeWorkerShift[]>([]);
  let { url } = useRouteMatch();
  const preparedUrl = getUrlWithoutLastParam(url);

  const timeModuleUser: User = useMemo(() => {
    return {
      first_name: worker?.first_name || '',
      last_name: worker?.last_name || '',
      gender: worker?.gender,
      photo: { url: worker?.avatar || undefined },
      uuid: worker?.user_id || '',
    };
  }, [worker]);

  const getUser = useCallback(async () => {
    const urlItems = getUrlItems(id);
    const userId = urlItems.id;
    if (!userId || !companyId) {
      return;
    }
    try {
      const res = await getTimeWorkerRequest(userId, companyId);
      const data = res.data;
      setWorker(data);
      setShits(data.shifts);
    } catch (e) {
      console.error(e);
    }
  }, [companyId, id]);

  const TABS: TabItem[] = useMemo(() => {
    return [
      {
        label: 'Profile',
        link: `${preparedUrl}/profile`,
      },
      {
        label: 'Time Logs',
        link: `${preparedUrl}/logs`,
      },
      {
        label: 'T&A Settings',
        link: `${preparedUrl}/settings`,
      },
    ];
  }, [preparedUrl]);

  const LocationsAndShiftsSettingsComponent = useCallback(
    () => (
      <LocationsAndShiftsSettings
        locations={locations}
        companyId={companyId}
        shits={shits}
        worker={worker}
        getUser={getUser}
      />
    ),
    [companyId, getUser, locations, shits, worker]
  );

  const UserPreviewComponent = useCallback(
    () =>
      userPreviewData || worker ? (
        <UserPreview
          rtw={rtw}
          passport={passport}
          userPreviewData={
            userPreviewData || {
              user: timeModuleUser,
            }
          }
          companyId={companyId ?? ''}
          isLegend={isLegend}
          showHealthAndSafetyPDFRequest={showHealthAndSafetyPDFRequest}
        />
      ) : null,
    [companyId, passport, rtw, showHealthAndSafetyPDFRequest, userPreviewData, worker]
  );

  const ActivityLogsComponent = useCallback(
    () => <ActivityLogs companyId={companyId ?? ''} userId={id} />,
    [companyId, id]
  );

  const back = () => {
    history.push('/time/users');
  };

  useEffect(() => {
    getUser();
  }, [id]);

  return (
    <>
      <Box margin={-5} marginTop="-22px">
        <BackTo backTo={{ text: nameOfPrevRoute, back }} />
        <Box
          display="flex"
          alignItems="center"
          paddingRight={5}
          paddingLeft={5}
          paddingBottom={3}
          bgcolor={theme.palette.background.paper}>
          <Avatar src={worker?.avatar || getAvatar(worker?.gender ?? 'male')} />
          <Box marginLeft={2}>
            <Typography variant="h6">
              {worker?.first_name} {worker?.last_name}
            </Typography>
          </Box>
        </Box>
        <AppTabs tabItems={TABS} />
      </Box>

      <Box marginTop={10}>
        {loading ? (
          <Box height="calc(100vh - 200px)">
            <PageLoading />
          </Box>
        ) : (
          <Switch>
            <Route path={`${preparedUrl}/settings`} component={LocationsAndShiftsSettingsComponent} />
            <Route path={`${preparedUrl}/profile`} component={UserPreviewComponent} />
            <Route path={`${preparedUrl}/logs`} component={ActivityLogsComponent} />
          </Switch>
        )}
      </Box>
    </>
  );
};
