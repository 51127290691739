import React, { useState } from 'react';

import { Box, MenuItem, MenuList } from '@material-ui/core';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

import {
  AppA,
  AppDataGridWithSavedPage,
  ConfirmDialog,
  GridActions,
  GridCellWithAvatar,
} from '@vyce/core/src/components';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { WorkerLookupDTO } from '@vyce/core/src/api/types';

interface Props {
  tableHeight?: string;
  workers: WorkerLookupDTO[];
  loading: boolean;
  total: number;
  sortModel: GridSortModel;
  handleSortModelChange: (newModel: GridSortModel) => void;
  handlePageChange: (newPage: number) => void;
  setOffset: (offset: number) => void;
  removeWorkerFromAllLocations: (userId: string) => void;
}

export const LocationUsers: React.FC<Props> = ({
  tableHeight = '443px',
  workers,
  loading,
  total,
  sortModel,
  setOffset,
  handleSortModelChange,
  handlePageChange,
  removeWorkerFromAllLocations,
}) => {
  const history = useHistory();
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const goToSettings = (name: string, userId: string) => {
    history.push(`/time/users/${name}_${userId}/profile`);
  };

  const handleRemoveWorkerFromAllLocations = async () => {
    if (selectedUserId) {
      setButtonLoading(true);
      await removeWorkerFromAllLocations(selectedUserId);
      setIsConfirmDeleteDialogOpen(false);
      setButtonLoading(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 0.2,
      minWidth: 140,
      disableColumnMenu: true,
      renderCell: params => (
        <GridCellWithAvatar
          link={`/time/users/${params.row.first_name}_${params.row.user_id}/profile`}
          avatarUrl={params.row.photo || params.row.avatar}
          avatarPlaceholder={getAvatar(params.row.gender)}
          name={`${params.row.first_name} ${params.row.last_name}`}
        />
      ),
    },
    {
      field: 'ni_number',
      headerName: 'Ref #',
      width: 95,
      flex: 0.1,
      disableColumnMenu: true,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 180,
      renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              <MenuItem onClick={() => goToSettings(params.row.first_name, params.row.user_id)}>
                See user's activity
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedUserId(params.row.user_id);
                  setIsConfirmDeleteDialogOpen(true);
                  resetCloseStatus();
                }}>
                Remove from all locations
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  return (
    <>
      <AppDataGridWithSavedPage
        noPaper
        rows={workers}
        loading={loading}
        height={tableHeight}
        getRowId={row => row.user_id}
        columns={columns}
        rowCount={total}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        setOffset={setOffset}
      />

      <ConfirmDialog
        handleClose={() => setIsConfirmDeleteDialogOpen(false)}
        open={isConfirmDeleteDialogOpen}
        confirmText="Remove"
        cancelText="No, cancel"
        loading={buttonLoading}
        title="Remove this user from all locations?"
        subtitle="Are you sure you want to remove this user from all locations? This action cannot be undone."
        handleConfirm={handleRemoveWorkerFromAllLocations}
      />
    </>
  );
};
