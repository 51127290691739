import { useState } from 'react';

import { defaultIsLoadingList } from '@vyce/core/src/components/InfiniteTable';

export const useInfiniteScroll = <T>(defaultData: T[]) => {
  const [data, setData] = useState<T[]>(defaultData);
  const [isLoadingList, setLoadingList] = useState<Record<number, boolean>>(defaultIsLoadingList);

  const resetLoadingList = () => {
    setLoadingList(defaultIsLoadingList);
  };

  const setNewData = ({ newData, offset }: { newData: T[]; offset: number }) => {
    if (offset === 0) {
      setData(newData);
      return;
    }
    setData(prevData => {
      const updatedData = [...prevData];
      newData.forEach((item, index) => {
        updatedData[offset + index] = item;
      });
      return updatedData;
    });
  };

  const setInitialLoadingStatusesFalse = (offset: number, pageSize: number) => {
    const itemStatusMap: Record<number, boolean> = {};
    for (let index = offset; index <= offset + pageSize; index++) {
      itemStatusMap[index] = true;
    }
    setLoadingList(items => {
      return { ...items, ...itemStatusMap };
    });
    return itemStatusMap;
  };

  const setInitialLoadingStatusesTrue = ({
    offset,
    pageSize,
    itemStatusMap,
  }: {
    offset: number;
    pageSize: number;
    itemStatusMap: Record<number, boolean>;
  }) => {
    for (let index = offset; index <= offset + pageSize; index++) {
      itemStatusMap[index] = false;
    }

    setLoadingList(items => {
      return offset === 0 ? { itemStatusMap } : { ...items, ...itemStatusMap };
    });
  };

  return {
    data,
    isLoadingList,
    setNewData,
    setLoadingList,
    setInitialLoadingStatusesFalse,
    setInitialLoadingStatusesTrue,
    resetLoadingList,
  };
};
