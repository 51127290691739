import React, { useCallback, useContext, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { Box, Grid, Paper, Typography, FormControlLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { BankAccount, CISStatusData, Employee, HelperState } from '@vyce/core/src/types';
import {
  JobsField,
  UTRNumberField,
  BankAccountName,
  BankAccountNumberField,
  SortCodeField,
  BuildingSocietyRollNumberField,
  EmergencyContactNameField,
  EmergencyContactNumberField,
  ControlledDateField,
} from '@vyce/core/src/components/controlled-inputs';
import { AppFormWrapper, EmptyList, VerifyYourIdentityDialog } from '@vyce/core/src/components';
import { checkEmployeeVATRequest } from '@vyce/core/src/api/legend/pay';
import { BUSINESS_TYPES } from '@vyce/core/src/constants';
import { useBooleanState } from '@vyce/core/src/hooks';
import config from '@vyce/core/src/assets/config';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { LimitedCompanyInputs } from '../components/LimitedCompanyInputs';
import { VATRegisteredInput } from '../components/VATRegisteredInput';
import { CISStatus } from '../components/CISStatus';
import { AssociatedPaySchedules } from '../components/AssociatedPaySchedules';
import useStyles from '../styles';
import { AppTextFieldWithLimit } from '../../../components/controlled-inputs/AppTextFieldWithLimit';
import { AppCheckbox } from '../../../components/inputs';

interface Props {
  updateEmployee: Function;
  helper: HelperState;
  employee?: Employee;
  email?: string;
  cisStatus?: CISStatusData;
  token?: string;
  isLegend?: boolean;
  userDataLoading?: boolean;
}

interface Form extends Employee {
  isLimitedCompany: boolean;
}

export const EmploymentInfo: React.FC<Props> = ({
  updateEmployee,
  helper,
  employee,
  cisStatus,
  isLegend,
  token,
  userDataLoading,
  email,
}) => {
  const { showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const history = useHistory();
  const [VATLoading, setVATLoadingTrue] = useBooleanState(false);
  const [formWasChanged, setFormWasChangedFlag] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState<Partial<Form> | null>(null);
  const methods = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      job_title: employee?.job_title,
      utr: employee?.utr,
      effective_date: employee?.effective_date,
      isLimitedCompany: !!employee?.company_name,
      company_name: employee?.company_name || undefined,
      company_reg_number: employee?.company_reg_number || undefined,
      company_utr: employee?.company_utr || undefined,
      vat_reg_number: employee?.vat_reg_number || undefined,
      vat_registered: employee?.vat_registered,
      bank_account: {
        account_name: employee?.bank_account?.account_name,
        account_number: employee?.bank_account?.account_number,
        sort_code: employee?.bank_account?.sort_code,
        building_society_roll_number: employee?.bank_account?.building_society_roll_number || undefined,
      },
      emergency_contact_name: employee?.emergency_contact_name || undefined,
      emergency_contact_number: employee?.emergency_contact_number || undefined,
      business_type: employee?.business_type,
      is_agency_worker: employee?.is_agency_worker,
      agency_ref_number: employee?.agency_ref_number,
    },
  });
  const { getValues, watch, handleSubmit } = methods;
  const [isLoginDialogOpen, openLoginDialog, closeLoginDialog] = useBooleanState(false);

  const vat_reg_number = watch('vat_reg_number');
  const is_agency_worker = watch('is_agency_worker');

  const checkVAT = async () => {
    const vat_number = getValues('vat_reg_number');
    if (!employee?.uuid || !vat_number) {
      return;
    }
    try {
      setVATLoadingTrue();
      await checkEmployeeVATRequest(employee.uuid, vat_number);
      showNotification({
        message: 'VAT check has been started. Reload the page to get the latest status later.',
        options: { variant: 'success' },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOutsideSubmit = () => handleSubmit(onSubmit)();

  const onChangeShowButton = useCallback((formWasChanged: boolean) => {
    setFormWasChangedFlag(formWasChanged);
  }, []);

  const isBankDetailsChanged = (formBankAccount: BankAccount) => {
    const employeeBankAccount = employee?.bank_account;
    return (
      employeeBankAccount?.account_name !== formBankAccount.account_name ||
      employeeBankAccount?.account_number !== formBankAccount.account_number ||
      employeeBankAccount?.sort_code !== formBankAccount.sort_code ||
      employeeBankAccount?.building_society_roll_number !== formBankAccount.building_society_roll_number
    );
  };

  const onSubmit = async (data: Form) => {
    if (vat_reg_number && isLegend) checkVAT();
    if (!formWasChanged) return;

    const dataForUpdate: Partial<Form> = {};
    // @ts-ignore
    Object.keys(data).forEach((key: keyof Employee) => {
      if (employee && data[key] !== employee[key]) {
        dataForUpdate[key] = data[key];
      }
      if (key === 'job_title') {
        dataForUpdate.job_title = typeof data.job_title === 'string' ? data.job_title : data.job_title.name;
      }
      if (key === 'business_type') {
        dataForUpdate.business_type = data.company_reg_number
          ? BUSINESS_TYPES.COMPANY
          : BUSINESS_TYPES.SOLE_TRADER;
      }
    });

    if (!isLegend && data.bank_account && isBankDetailsChanged(data.bank_account)) {
      setDataForUpdate(dataForUpdate);
      return openLoginDialog();
    }
    await updateEmployee(dataForUpdate, employee?.uuid);
  };

  return (
    <Box paddingBottom={10}>
      {employee ? (
        <AppFormWrapper
          methods={methods}
          initialData={employee}
          prePopulateValues={false}
          handleSubmit={onSubmit}
          loading={userDataLoading}
          changeShowButton={onChangeShowButton}>
          <>
            <Paper className={classes.formPaper} variant="outlined">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <JobsField
                    label="Job Title"
                    name="job_title"
                    rules={{ required: 'Job Title is required' }}
                    multiple={false}
                  />
                </Grid>
                {isLegend && (
                  <>
                    <Grid item xs={12} md={6}>
                      <AppTextFieldWithLimit
                        label="Our reference number"
                        name="agency_ref_number"
                        limit={32}
                        disabled={!isLegend}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box width="100%" height="100%" display="flex" alignItems="center">
                        <Controller
                          name="is_agency_worker"
                          control={methods.control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <AppCheckbox
                                  onChange={e => {
                                    const checked = e.target.checked;
                                    field.onChange(checked);
                                  }}
                                  disabled={!isLegend}
                                  color="primary"
                                  checked={field.value}
                                />
                              }
                              label="Is an agency worker?"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} lg={6}>
                  <UTRNumberField />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <ControlledDateField name="effective_date" label="Start Date" />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <LimitedCompanyInputs />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <VATRegisteredInput
                    verified={!!employee?.vat_registered}
                    value={employee?.vat_reg_number}
                    checkVAT={isLegend ? handleOutsideSubmit : undefined}
                    loading={VATLoading}
                    verifyDisabled={VATLoading || !vat_reg_number}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Box marginRight={1} marginBottom={2} marginTop={2}>
              <Typography className={classes.tabTitle}>Bank Details</Typography>
            </Box>

            <Paper className={classes.formPaper} variant="outlined">
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <BankAccountName required={false} />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <BankAccountNumberField required={false} />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SortCodeField required={false} />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <BuildingSocietyRollNumberField />
                </Grid>
              </Grid>
            </Paper>

            <Box marginRight={1} marginBottom={2} marginTop={2}>
              <Typography className={classes.tabTitle}>Emergency Contacts</Typography>
            </Box>

            <Paper className={classes.formPaper} variant="outlined">
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <EmergencyContactNameField />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <EmergencyContactNumberField />
                </Grid>
              </Grid>
            </Paper>

            {
              <Box marginTop={2}>
                <CISStatus employee={employee} cisStatus={cisStatus} showButton={!!isLegend} />
              </Box>
            }

            {isLegend && (
              <Box marginTop={2}>
                <AssociatedPaySchedules employeeId={employee.uuid} />
              </Box>
            )}
          </>
        </AppFormWrapper>
      ) : (
        <Box display="flex" justifyContent="center" width="100%">
          <EmptyList
            image={config.icons3D.lightning}
            subtitle={
              'This is where your payroll information will be kept when you get added to a companies payroll on Vyce. Until then, there’s nothing to see...!'
            }
            title={'Nothing here yet!'}
          />
        </Box>
      )}

      {email && (
        <VerifyYourIdentityDialog
          message="To update bank details, please re-confirm your password."
          email={email}
          open={isLoginDialogOpen}
          handleClose={closeLoginDialog}
          handleCancel={() => {
            history.push('/profile');
            closeLoginDialog();
          }}
          handleNext={() => updateEmployee(dataForUpdate, employee?.uuid)}
        />
      )}
    </Box>
  );
};
