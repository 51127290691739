import React from 'react';

import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Box, Theme, useTheme } from '@material-ui/core';

import { monochrome } from '@vyce/core/src/theme/styles';
import { PageLoading } from '@vyce/core/src/components';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { useStyles } from './styles';
import { useChartData } from './hooks';
import { Props } from './types';
import { CustomBarComponent, CustomBottomTick, PageButtons, Legend } from './components';
import { marginOptions, keys } from './config';

export const WorkersOvertimeWidget = (props: Props & WidgetWrapperProps): JSX.Element => {
  const theme = useTheme<Theme>();
  const classes = useStyles();

  const { loading, ref, cuttedData, showDecreaseButton, showIncreaseButton, increasePage, decreasePage } =
    useChartData(props);

  return (
    <WidgetWrapper {...props}>
      <div ref={ref} className={classes.chartWrapper}>
        {loading ? (
          <PageLoading />
        ) : (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            position="relative"
            className={classes.barContainer}>
            <ResponsiveBar
              data={cuttedData as unknown as BarDatum[]}
              keys={keys}
              indexBy="weekNumber"
              margin={marginOptions}
              padding={0.6}
              colorBy="id"
              groupMode="stacked"
              valueFormat=" >-"
              theme={{
                labels: {
                  text: {
                    fill: theme.palette.type === 'dark' ? monochrome.dark : monochrome.lightest,
                    fontSize: 13,
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                axis: {
                  ticks: {
                    line: {
                      display: 'none',
                    },
                    text: {
                      fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,
                      fontSize: 13,
                      fontFamily: 'inherit',
                      fontWeight: 500,
                      lineHeight: '22px',
                      transform: 'translate(-4px, 0) rotate(0deg)',
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 13,
                      fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,
                      fontFamily: 'inherit',
                      fontWeight: 600,
                      lineHeight: '22px',
                    },
                  },
                },
              }}
              axisBottom={{
                renderTick: CustomBottomTick,
              }}
              axisLeft={{
                format: e => Math.floor(e) === e && e,
                tickValues: 5,
              }}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate={false}
              axisTop={null}
              axisRight={null}
              labelSkipWidth={22}
              labelSkipHeight={12}
              barComponent={CustomBarComponent}
            />
          </Box>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          position="absolute"
          top={0}
          gridGap={16}
          width="100%">
          <Legend />
          <PageButtons
            showIncreaseButton={showIncreaseButton}
            showDecreaseButton={showDecreaseButton}
            decreasePage={decreasePage}
            increasePage={increasePage}
          />
        </Box>
      </div>
    </WidgetWrapper>
  );
};
