import { Box } from '@material-ui/core';
import { EnrichedColumnProps } from '../types';

export const RowItem = <T extends Record<keyof T, any>>({
  row,
  column,
}: {
  row: T;
  column: EnrichedColumnProps<T>;
}) => {
  const getValue = (): React.ReactNode => {
    if (column.field !== 'customGetter' && column.renderCell) return column.renderCell(row);
    if (column.field !== 'customCell' && column.valueGetter) return column.valueGetter(row);
    return row[column.field as keyof T] ?? null;
  };
  return (
    <Box
      minWidth={column.width || 'auto'}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      textAlign="start">
      {getValue()}
    </Box>
  );
};
