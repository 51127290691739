import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, InputLabel, MenuItem, Paper, Typography, useTheme } from '@material-ui/core';
import { RiEyeLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { TimeModuleContext } from '@vyce/core/src/contexts';
import { AppCustomTable, AppIconButton } from '@vyce/core/src/components';
import {
  TimeLogByShiftDateItemLog,
  TimeAndAttendanceLocation,
  TimeLogByShiftDateItem,
  TimeTotalInfoProps,
  AppCustomTableColumn,
} from '@vyce/core/src/types';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { getTimeLogsByShiftRequest } from '@vyce/core/src/api/time';
import { formatDate, getTime } from '@vyce/core/src/utils/dates';
import { AppSelect, AppRangeDatePicker } from '@vyce/core/src/components/inputs';

import { useStyles } from '../styles';
import { WorkerLog } from './components';
import { getFaceTitle } from './utils/getFaceTitle';
import { getLocationTitle } from './utils/getLocationTitle';
import { NotificationContext } from '../../../contexts/notificationContext';
import { TimeLogWarningCell, WorkerHoursCell } from '../../../components/EditWorkerHoursDialog';

const TimeContainer = ({ time, width, color }: { time: string | number; width: number; color?: string }) => {
  const theme = useTheme();

  return (
    <Box
      height={48}
      width={width}
      display="flex"
      alignItems="center"
      justifyContent="center"
      border={`1px solid ${theme.palette.divider}`}
      borderRadius="8px">
      <Typography style={{ color }} variant="subtitle2">
        {time}
      </Typography>
    </Box>
  );
};
interface Props {
  companyId?: string;
  userId: string;
}

const nowDate = dayjs();

export const ActivityLogs = ({ userId, companyId }: Props) => {
  const [logs, setLogs] = useState<TimeLogByShiftDateItem[]>([]);
  const [totals, setTotals] = useState<TimeTotalInfoProps>();
  const [selectedLocation, setSelectedLocation] = useState<TimeAndAttendanceLocation>();
  const [dateFrom, setDateFrom] = useState<string>(nowDate.subtract(7, 'day').startOf('day').format());
  const [dateTo, setDateTo] = useState<string>(nowDate.endOf('day').format());
  const { locations } = useContext(TimeModuleContext);
  const { handleServerError } = useContext(NotificationContext);

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const getActivityLogEvents = async (siteId?: string) => {
    if (!companyId) {
      return;
    }

    const urlItems = getUrlItems(userId);

    try {
      const { data } = await getTimeLogsByShiftRequest(companyId, urlItems.id, {
        start_date: formatDate(dateFrom),
        end_date: formatDate(dateTo),
        site_id: siteId,
      });
      setLogs(data.date_items || []);
      setTotals(data.totals);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getActivityLogEvents();
  }, [dateFrom, dateTo]);

  const handleLocationChange = (loc?: TimeAndAttendanceLocation) => {
    setSelectedLocation(loc);
    getActivityLogEvents(loc?.uuid);
  };

  const columns: AppCustomTableColumn[] = useMemo(
    () => [
      {
        name: 'date',
        title: 'Date',
        flex: 0.3,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box display="flex" alignItems="center" height={70} lineHeight="48px">
            {formatDate(row?.date, 'dddd D MMMM')}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Shift',
        flex: 0.2,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <Box display="flex" alignItems="center" height={70} key={'shift' + index}>
                {log.shift_name}
              </Box>
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clock In Time',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <Box
                display="flex"
                alignItems="center"
                style={{ fontWeight: 600 }}
                height={70}
                key={`clock-in${index}`}>
                <>
                  {log.checked_in ? (
                    <TimeLogWarningCell check_in_ok={log.check_in_ok} face_in_ok={log.face_in_ok}>
                      <>{getTime(log.checked_in)}</>
                    </TimeLogWarningCell>
                  ) : (
                    '-'
                  )}
                </>
              </Box>
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clock Out Time',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <Box
                display="flex"
                alignItems="center"
                style={{ fontWeight: 600 }}
                height={70}
                key={`clock-out${index}`}>
                <>
                  {log.checked_out ? (
                    <TimeLogWarningCell
                      check_out_ok={log.check_out_ok}
                      face_out_ok={log.face_out_ok}
                      auto_clock_out={log.auto_clock_out}
                      manual_clock_out={log.manual_clock_out}>
                      <>{getTime(log.checked_out)}</>
                    </TimeLogWarningCell>
                  ) : (
                    '-'
                  )}
                </>
              </Box>
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clocked Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <WorkerHoursCell
                key={`clocked-hours${index}`}
                decimals={log.clocked_in_hours_decimals}
                amendmentDecimals={0}
              />
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Regular Paid Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <WorkerHoursCell
                key={`regular-hours${index}`}
                decimals={log.basic_hours_decimals}
                amendmentDecimals={log.basic_amendment_hours_decimals}
              />
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Overtime Paid Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <WorkerHoursCell
                key={`ot-hours${index}`}
                decimals={log.overtime_hours_decimals}
                amendmentDecimals={log.overtime_amendment_hours_decimals}
              />
            ))}
          </Box>
        ),
      },
      {
        name: 'View',
        title: 'View',
        flex: 0.04,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={70}
                key={`view-${index}`}>
                <AppIconButton
                  onClick={() =>
                    history.push(
                      `${history.location.pathname}/${formatDate(row?.date)}?shiftId=${log.shift_id}`
                    )
                  }>
                  <RiEyeLine size="18px" />
                </AppIconButton>
              </Box>
            ))}
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Time Logs</Typography>
        <Box display="flex" gridGap={8} alignItems="center">
          <AppRangeDatePicker
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
          <Box width={200}>
            <FormControl fullWidth variant="filled">
              <InputLabel>Location</InputLabel>
              <AppSelect value={selectedLocation?.uuid || 'All'}>
                <MenuItem onClick={() => handleLocationChange()} value={'All'}>
                  All
                </MenuItem>
                {locations.map(item => (
                  <MenuItem onClick={() => handleLocationChange(item)} key={item.uuid} value={item.uuid}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppSelect>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box marginTop={3} overflow="auto">
        <AppCustomTable columns={columns} data={logs} />

        <Box className={classes.totalContainer} minWidth={900}>
          <Box flex={0.7}>
            <Typography variant="subtitle2">Total</Typography>
          </Box>

          <Box flex={0.18} display="flex" gridGap={8} alignItems="center">
            <TimeContainer color={theme.palette.secondary.main} width={56} time={totals?.basic_hours || 0} />
            <Typography variant="subtitle2">:</Typography>
            <TimeContainer
              color={theme.palette.secondary.main}
              width={56}
              time={totals?.basic_minutes || 0}
            />
          </Box>

          <Box flex={0.22} display="flex" gridGap={8} alignItems="center">
            <TimeContainer
              color={theme.palette.secondary.main}
              width={56}
              time={totals?.overtime_hours || 0}
            />
            <Typography variant="subtitle2">:</Typography>
            <TimeContainer
              color={theme.palette.secondary.main}
              width={56}
              time={totals?.overtime_minutes || 0}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

ActivityLogs.WorkerLog = WorkerLog;
ActivityLogs.getFaceTitle = getFaceTitle;
ActivityLogs.getLocationTitle = getLocationTitle;
