import React, { useMemo } from 'react';

import { Box, Typography, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import noop from 'lodash/noop';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { GridCellWithAvatar } from '@vyce/core/src/components';
import { ControlledAutocomplete } from '@vyce/core/src/components/controlled-inputs';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { TextStatusComponent } from '@vyce/core/src/components/Statuses/TextStatusComponent';
import { EnrichedColumnProps } from '@vyce/core/src/components/InfiniteTable/types';
import { InfiniteTable } from '@vyce/core/src/components/InfiniteTable';
import { TeamQualificationWidgetDTO } from '@vyce/core/src/api/types';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { useStyles } from './styles';
import { defaultStatusOptions, statuses } from './config';

/* Team's Qualifications widget */
export const QualificationWidget = (props: Props & WidgetWrapperProps) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    teamQualifications,
    qualificationsSelectOptions,
    teamsSelectOptions,
    selectedTeamId,
    total,
    selectedStatusId,
    methods,
    isLoadingList,
    resetLoadingList,
    setSelectedTeamId,
    setSelectedStatusId,
    getTeamQualifications,
  } = useWidgetData(props);

  const columns: EnrichedColumnProps<TeamQualificationWidgetDTO>[] = useMemo(
    () => [
      {
        field: 'first_name',
        headerName: 'Name',
        flex: 1,
        width: 150,
        sortable: false,
        renderCell: row => (
          <GridCellWithAvatar
            avatarUrl={row.photo}
            avatarPlaceholder={getAvatar(row.gender)}
            name={`${row.first_name} ${row.last_name}`}
            link={`${history.location.pathname}/${row?.first_name}_${row?.user_id}`}
          />
        ),
      },
      {
        field: 'title',
        headerName: 'Qualification',
        flex: 0.8,
        width: 100,
        sortable: false,
        valueGetter: row => row?.title ?? '',
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.8,
        width: 100,
        sortable: false,
        renderCell: row => (
          <TextStatusComponent
            verified={row?.status !== statuses.Indefinite}
            expiry_date={row?.expiry_date}
          />
        ),
      },
    ],
    []
  );

  const handleTeamChange = (event: any) => {
    setSelectedTeamId(event.target.value);
  };

  const handleStatusChange = (event: any) => {
    setSelectedStatusId(event.target.value);
  };

  return (
    <WidgetWrapper {...props}>
      <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={12}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(noop)}>
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
              gridGap={12}>
              <AppSelect
                onChange={handleTeamChange}
                value={selectedTeamId}
                labelId="select-trade"
                fullWidth
                className={classes.selector}>
                {teamsSelectOptions.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppSelect>
              <Box display="flex" width="100%" alignItems="center" gridGap={8}>
                <Typography>with</Typography>

                <ControlledAutocomplete
                  name="qualification"
                  margin="none"
                  items={qualificationsSelectOptions}
                  label="Qualification"
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" gridGap={8}>
                <Typography>at</Typography>
                <AppSelect
                  onChange={handleStatusChange}
                  value={selectedStatusId}
                  labelId="select-status"
                  fullWidth
                  className={classes.selector}>
                  {defaultStatusOptions.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </AppSelect>
                <Typography>status</Typography>
              </Box>
            </Box>
          </form>
        </FormProvider>

        <InfiniteTable
          data={teamQualifications}
          columns={columns}
          rowCount={total}
          rowHeight={54}
          height="calc(100% - 78px)"
          pageSize={GRID_PAGE_SIZE}
          isLoadingList={isLoadingList}
          onLoadMore={getTeamQualifications}
          resetLoadingList={resetLoadingList}
        />
      </Box>
    </WidgetWrapper>
  );
};
